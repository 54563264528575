<template>
  <div>
    <!-- 公共头部 -->
   <Header></Header>
    <img class="status_bg" src="@/assets/images/status_bg.png" />

    <div class="section">
      <div class="view1">
        <img
          class="icon"
          src="@/assets/images/status1.png"
          v-if="achievementInfo.result == '成绩不合格'"
        />
        <img class="icon" src="@/assets/images/status2.png" v-else />
        <div class="names">{{ achievementInfo.name }}</div>
        <div class="info">
          <div class="item">
            <div class="item_title">项目名称:</div>
            <div class="item_name">
              {{ achievementInfo.title }}
            </div>
          </div>
          <div class="item">
            <div class="item_title">交卷时间:</div>
            <div class="item_name">{{ uptime }}</div>
          </div>
        </div>
        <div class="list clearfix">
          <div class="item fl clearfix">
            <span class="fl title">分数：</span>
            <div class="value">{{ achievementInfo.scores }}</div>
          </div>
          <div class="item fl clearfix">
            <span class="fl title">用时：</span>
            <div class="value">{{ achievementInfo.yongshi }}</div>
          </div>
          <div class="item fl clearfix">
            <span class="fl title">答对：</span>
            <div class="value green">{{ achievementInfo.sure }}题</div>
          </div>
          <div class="item fl clearfix">
            <span class="fl title">答错：</span>
            <div class="value red">{{ achievementInfo.wrong }}题</div>
          </div>
          <div class="item fl clearfix">
            <span class="fl title">漏答：</span>
            <div class="value red">{{ achievementInfo.leak }}题</div>
          </div>
          <div class="item fl clearfix">
            <span class="fl title">答对率：</span>
            <div class="value green">{{ achievementInfo.accuracy }}</div>
          </div>
        </div>
      </div>
      <div class="button-box">
        <router-link
          :to="'/simulation/review?exam_id=' + achievementInfo.exam_id"
          type="button"
          class="btn btn1"
          >考试回顾</router-link
        >
        <div
          class="btn"
          @click="$router.replace({ path: '/simulation/index' })"
        >
          返回
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
props: [];
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      achievementInfo: "",
      uptime: "",
    };
  },
  computed: {
    uid() {
       return this.$store.state.uid;
    },
  },
  mounted() {},
  activated() {
    this.getUserInfo();
    this.uptime = this.$route.query.time;
  },
  deactivated() {
    this.achievementInfo = "";
  },
  methods: {
    // 考试成绩查询
    async getInfo() {
      var exam_id = this.$route.query.id;
      var uid = this.uid;
      var data = {
        exam_id,
        uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/study/achievement", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "全真模拟 成绩查询：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        var achievementInfo = res.data;
        this.achievementInfo = achievementInfo;
      } else {
        this.achievementInfo = "";
        this.$toast.error(res.data);
      }
    },
    // 获取用户信息
    async getUserInfo() {
      if (!this.uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
          return;
        }
      		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
        this.getInfo();
      }
    },
  },
};
</script>

<style scoped>
.status_bg {
  position: fixed;
  bottom: 1rem;
  left: 0;
  width: 100%;
}
.section {
  width: 6.9rem;
  margin: 0 auto;
  padding-top: 1.2rem;
}
.section .view1 {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  position: relative;
}
.section .view1 .icon {
  position: absolute;
  width: 2.02rem;
  height: 2.02rem;
  top: 0.35rem;
  right: 0.7rem;
}
.section .view1 .names {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.34rem;
}
.section .view1 .info {
  text-align: center;
}
.section .view1 .info .item {
  font-size: 0.26rem;
  color: #333333;
  margin-bottom: 0.24rem;
  display: flex;
  padding: 0 0.4rem;
  justify-content: space-between;
}
.section .view1 .info .item:last-child {
  margin-bottom: 0;
}
.section .view1 .list {
  padding: 0 0.4rem;
}
.section .view1 .list .item {
  width: 33.33%;
  margin-top: 0.6rem;
}
.section .view1 .list .item .title {
  font-size: 0.26rem;
  color: #333333;
  line-height: 100%;
}
.section .view1 .list .item .value {
  font-size: 0.26rem;
  color: #333333;
  line-height: 100%;
}
.section .view1 .list .item .value.green {
  color: #55b71b;
}
.section .view1 .list .item .value.red {
  color: #fc2222;
}
.section .button-box {
  margin-top: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8rem;
}
.section .button-box .btn {
  width: 2.2rem;
  height: 0.8rem;
  background: #121e5f;
  border-radius: 0.5rem;
  font-size: 0.28rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section .button-box .btn1 {
  background: #ff6000;
}
</style>